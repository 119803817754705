import { createContext, useContext, ReactNode } from "react";
import { Config } from './Types';
import AgentAPI from './AgentAPI';
import * as Rafiki from '@sandtreader/rafiki';

// Deploy-specific config, read from public/config.js
declare global {
  var appConfig: Config;
}

interface AppContextType {
  config: Config;
  agentApi: AgentAPI;
  session?: Rafiki.SessionState;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppContextProviderProps {
  children: ReactNode;
  session?: Rafiki.SessionState;
}

export const AppContextProvider = (
  { children, session }: AppContextProviderProps) => {

  const config = window.appConfig;
  const agentApi = new AgentAPI(appConfig.agent.url, session);

  return (
    <AppContext.Provider value={{ config: config,
                                  agentApi: agentApi,
                                  session: session
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};
