// REST API client for mpd-agent
import * as Rafiki from '@sandtreader/rafiki';

// Definition of an entity-to-entity relationship
export class Relationship
{
  public subject_id: number;
  public type: string;
  public object_id: number;

  constructor(subject_id: number, type: string, object_id: number)
  {
    this.subject_id = subject_id;
    this.type = type;
    this.object_id = object_id;
  }
};

// Definition of an agent entity
export class Entity
{
  public id: number;
  public name: string = "";
  public description: string = "";
  public type: string = "";
  public relationships: Relationship[] = [];

  constructor(id: number)
  {
    this.id = id;
  }
};

// API module
class AgentAPI
{
  public readonly url: string;
  public readonly session?: Rafiki.SessionState;

  // Constructor
  constructor(url: string, session?: Rafiki.SessionState)
  {
    this.url = url;
    this.session = session;
  }

  // Get a single entity (full details, including description and relationships)
  public async getEntity(id: number): Promise<Entity>
  {
    try
    {
      const response = await fetch(this.url + `/entity/${id}`, {
        headers: this.getAuthHeaders()
      });
      if (response.ok)
      {
        const json = await response.json();
        const id = json["id"];
        if (!id) throw new Error("Bad JSON response");

        const entity = new Entity(id);
        entity.name = json["name"];
        entity.description = json["description"];
        entity.type = json["type"];

        if (Array.isArray(json["rel"]))
          for(const re of json["rel"])
            entity.relationships.push({
              subject_id: id,
              type: re["type"],
              object_id: re["id"]
            });

        return entity;
      }
      else throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }
  }

  // List entities of the given type (id and name only)
  public async listEntities(type: string): Promise<Array<Entity>>
  {
    const results: Entity[] = [];

    try
    {
      const response = await fetch(this.url + `/entities?type=${type}`, {
        headers: this.getAuthHeaders()
      });
      if (response.ok)
      {
        const json = await response.json();
        if (!Array.isArray(json)) throw new Error("Bad JSON response");

        for(const je of json)
        {
          const entity = new Entity(je["id"]);
          entity.name = je["name"];
          results.push(entity);
        }
      }
      else throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }

    return results;
  }

  // Create an entity of the given name, returning its ID
  public async createEntity(name: string, type: string): Promise<number>
  {
    try
    {
      const response = await fetch(this.url + `/entity`, {
        method: "POST",
        headers: this.getAuthHeaders(),
        body: JSON.stringify({ "name": name, "type": type })
      });

      if (response.ok)
      {
        const json = await response.json();
        if (typeof json != "object") throw new Error("Bad JSON response");
        return json["id"];
      }
      else throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }
  }

  // Update an entity
  public async updateEntity(entity: Entity)
  {
    try
    {
      const response = await fetch(this.url + `/entity/${entity.id}`, {
        method: "PUT",
        headers: this.getAuthHeaders(),
        body: JSON.stringify(entity)
      });

      if (!response.ok)
        throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }
  }

  // Delete an entity
  public async deleteEntity(entityId: number)
  {
    try
    {
      const response = await fetch(this.url + `/entity/${entityId}`, {
        method: "DELETE",
        headers: this.getAuthHeaders(),
      });

      if (!response.ok)
        throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }
  }

  // Get a a property
  public async getProperty(entityId: number, name: string): Promise<string>
  {
    try
    {
      const response = await fetch(this.url + `/property/${entityId}/${name}`,{
        headers: this.getAuthHeaders(),
      });
      if (response.ok)
        return response.text();
      else
        throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }
  }

  // Set a property
  public async setProperty(entityId: number, name: string, value: string)
  {
    try
    {
      const response = await fetch(this.url + `/property/${entityId}/${name}`,
      {
        method: "POST",
        headers: this.getAuthHeaders(),
        body: value
      });

      if (!response.ok)
        throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }
  }

  // Delete a property
  public async deleteProperty(entityId: number, name: string)
  {
    try
    {
      const response = await fetch(this.url + `/entity/${entityId}/${name}`, {
        method: "DELETE",
        headers: this.getAuthHeaders(),
      });

      if (!response.ok)
        throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }
  }

  // Add a relationship between two entities
  public async addRelationship(rel: Relationship)
  {
    try
    {
      const response = await fetch(this.url + `/relationship`, {
        method: "POST",
        headers: this.getAuthHeaders(),
        body: JSON.stringify({ "subject": rel.subject_id,
                               "type": rel.type,
                               "object": rel.object_id })
      });

      if (!response.ok)
        throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }
  }

  // Remove a relationship between two entities
  public async removeRelationship(rel: Relationship)
  {
    try
    {
      const response = await fetch(this.url + `/relationship`, {
        method: "DELETE",
        headers: this.getAuthHeaders(),
        body: JSON.stringify({ "subject": rel.subject_id,
                               "type": rel.type,
                               "object": rel.object_id })
      });

      if (!response.ok)
        throw new Error(`${response.status} ${response.statusText}`);
    }
    catch (e)
    {
      throw new Error(`Fetch failed: ${e}`);
    }
  }

  // Create auth headers for the session
  private getAuthHeaders(): { [ name: string]: string }
  {
    const headers: { [ name: string]: string } = {};
    if (this.session && this.session.jwt)
      headers["authorization"] = `bearer ${this.session.jwt}`;

    return headers;
  }

}

export default AgentAPI;
