import { useEffect, useState } from 'react';
import * as Rafiki from '@sandtreader/rafiki';
import { Config } from  './Types';
import { AppContextProvider } from './AppContext';

// Deploy-specific config, read from public/config.js
declare global {
  var appConfig: Config;
}

const config = window.appConfig;

// Fix up URLs with our hostname to make it location independent
if (config.auth.jwt && config.auth.jwt.url)
  config.auth.jwt.url = config.auth.jwt.url.replace("$HOST",
                                                    window.location.hostname);
if (config.agent.url)
  config.agent.url = config.agent.url.replace("$HOST",
                                              window.location.hostname);

if (config.websocket.url)
  config.websocket.url = config.websocket.url.replace("$HOST",
                                                      window.location.hostname);

// Authentication provider
function getAuthProvider(): Rafiki.AuthenticationProvider
{
  if (config.auth.jwt)
    return new Rafiki.JWTAuthenticationProvider(config.auth.jwt.url);
  else if (config.auth.fake)
    return new Rafiki.FakeAuthenticationProvider();

  throw new Error("No authentication mechanism configured");
}

const authProvider = getAuthProvider();

// Menu providers, dynamically loaded
async function getMenuProvider(): Promise<Rafiki.MenuProvider>
{
  // Baseline empty structure
  const wholeMenu = new Rafiki.MenuStructure('root', 'root');

  for(const menuName of config.menus)
  {
    console.log(`Loading ${menuName}`);
    try
    {
      const menu = await import(
        /* webpackExclude: /\.test\.ts(x)?$/ */
        `./${menuName}`
      );

      // Merge this as a child of the root
      const menuChild = Rafiki.MenuStructure.fromLiteral({
        children: [ menu.default ]
      });
      wholeMenu.combineWith(menuChild);
    }
    catch (e)
    {
      console.log(`Unable to load module ${menuName}: ${e}`);
    }
  }

  // Menu structure provider
  return new Rafiki.StaticMenuProvider(wholeMenu);
}

// Top-level App
function App() {
  const [session, setSession] = useState<Rafiki.SessionState>();
  const [menus, setMenus] = useState<Rafiki.MenuProvider>();

  // Load menus at start - note has to be an effect because async
  useEffect(() => {
    (async () => setMenus(await getMenuProvider()))();
  }, []);

  // Update session after login/logout
  const onSessionChange = (session: Rafiki.SessionState) => {
    setSession(session);
  };

  return (
    <AppContextProvider session={session}>
      { menus &&
        <Rafiki.Framework authProvider={authProvider} menuProvider={menus}
                          title="Metaphysical Studio"
                          onSessionChange={onSessionChange} />
      }
    </AppContextProvider>
  );
}

export default App;
